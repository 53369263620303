import { camelCase } from 'lodash';

import {
  CASE_MANAGER,
  EPISODE_CLASSIFICATION,
  LATEST_REHAB_STATE,
  NONE_OPTION,
  PHYSICIAN_GROUP,
  PHYSICIAN_TEAM,
  PLAN_TYPE_CLASSIFICATION,
  UTILIZATION_MANAGER,
} from 'constants/filterKeysConstants';
import {
  HOSPITAL,
  PAYER,
  PHYSICIAN_GROUP as PHYS_GROUP_API_TYPE,
  PHYSICIAN_TEAM as PHYS_TEAM_API_TYPE,
} from 'constants/locationTypes';
import { ClassificationApiName } from 'models/Classification';
import { ClientType } from 'models/Client';
import GroupType from 'models/GroupType';
import RehabState from 'models/RehabState';
import User from 'models/User';
import { useInfiniteAttrValues } from 'services/api/attrValue';
import { useInfiniteClassifications } from 'services/api/classification';
import { useInfiniteClientOptions } from 'services/api/client';
import { useInfiniteGroups } from 'services/api/group';
import { useSearchableRehabStates } from 'services/api/rehabStates';
import { useInfiniteUsers } from 'services/api/user';

// Slideout Section Title constants
export const COMANAGEMENT_TEAM = 'Co-Management Team';
export const ADDITIONAL_FILTERS = 'Additional Filters';

export enum FILTER_TYPES {
  DROPDOWN = 'dropdown',
  CHECKBOX = 'checkbox',
}

const defaultFilterDropdownConfig = {
  getOptionLabel: (obj) => obj.name,
  getOptionValue: (obj) => obj.id,
  filterType: FILTER_TYPES.DROPDOWN,
};

// Static Dropdowns
export const PHYSICIAN_GROUP_DROPDOWN = {
  ...defaultFilterDropdownConfig,
  placeholder: 'Physician Group',
  name: PHYSICIAN_GROUP,
  getOptions: {
    hook: useInfiniteGroups,
    params: {
      groupType: '',
      locationType: '',
      type: PHYS_GROUP_API_TYPE,
      sortBy: 'name asc',
    },
  },
};

export const HOSPITAL_DROPDOWN = {
  ...defaultFilterDropdownConfig,
  placeholder: 'Hospital',
  name: HOSPITAL,
  getOptions: {
    hook: useInfiniteGroups,
    params: {
      groupType: '',
      locationType: '',
      type: HOSPITAL,
      sortBy: 'name asc',
    },
  },
};

export const PHYSICIAN_TEAM_DROPDOWN = {
  ...defaultFilterDropdownConfig,
  placeholder: 'Physician',
  name: PHYSICIAN_TEAM,
  getOptionValue: (obj) => obj.name,
  getOptions: {
    hook: useInfiniteAttrValues,
    params: {
      distinctDisplayName: true,
      type: PHYS_TEAM_API_TYPE,
      active: true,
    },
  },
};

export const PAYER_DROPDOWN = {
  ...defaultFilterDropdownConfig,
  placeholder: 'Payer',
  name: PAYER,
  getOptions: {
    hook: useInfiniteGroups,
    params: {
      groupType: '',
      locationType: '',
      type: PAYER,
      sortBy: 'name asc',
    },
  },
};

export const PLAN_TYPE_DROPDOWN = {
  ...defaultFilterDropdownConfig,
  placeholder: 'Plan Type',
  name: PLAN_TYPE_CLASSIFICATION,
  getOptions: {
    hook: useInfiniteClassifications,
    params: {
      type: ClassificationApiName.PLAN_TYPE,
      sortBy: 'name asc',
    },
    optionsToPrepend: [NONE_OPTION],
  },
};

export const EPISODE_TYPE_DROPDOWN = {
  ...defaultFilterDropdownConfig,
  placeholder: 'Episode Type',
  name: EPISODE_CLASSIFICATION,
  getOptions: {
    hook: useInfiniteClassifications,
    params: {
      type: ClassificationApiName.EPISODE,
      sortBy: 'name asc',
    },
    optionsToPrepend: [NONE_OPTION],
  },
};

export const LATEST_REHAB_STATE_DROPDOWN = {
  ...defaultFilterDropdownConfig,
  placeholder: 'Stage',
  name: LATEST_REHAB_STATE,
  dataCy: 'stage',
  getOptionLabel: (rehabState: RehabState) => rehabState.state,
  getOptions: {
    hook: useSearchableRehabStates,
  },
};

export const CASE_MANAGER_DROPDOWN = {
  ...defaultFilterDropdownConfig,
  placeholder: 'Case Manager',
  name: CASE_MANAGER,
  getOptionLabel: (user: User) => user.fullName,
  getOptions: {
    hook: useInfiniteUsers,
    params: {
      include: 'credential',
      sortBy: 'name asc',
      activeOrUserRole: 'case_manager',
    },
  },
};

export const UTILIZATION_MANAGER_DROPDOWN = {
  ...defaultFilterDropdownConfig,
  placeholder: 'Utilization Manager',
  name: UTILIZATION_MANAGER,
  getOptionLabel: (user: User) => user.fullName,
  getOptions: {
    hook: useInfiniteUsers,
    params: {
      include: 'credential',
      sortBy: 'name asc',
      activeOrUserRole: 'utilization_manager',
    },
  },
};

// Dynamic Dropdowns. Use for creating a dropdown config for a provider.
export const createProviderDropdown = (providerType: GroupType, additionalParams?: object) => ({
  ...defaultFilterDropdownConfig,
  placeholder: providerType.displayName,
  name: camelCase(providerType.apiName),
  getOptions: {
    hook: useInfiniteGroups,
    params: {
      groupType: providerType.id,
      sortBy: 'name asc',
      ...additionalParams,
    },
  },
});

// Maintains the previously decided order of the filters based on clientType.
export const getComanagementTeamAcuteFilters = (actingClientType: ClientType) => {
  switch (actingClientType) {
    case ClientType.HEALTH_SYSTEM:
      return [HOSPITAL_DROPDOWN, PHYSICIAN_TEAM_DROPDOWN];
    case ClientType.PHYSICIAN_GROUP:
      return [PHYSICIAN_GROUP_DROPDOWN, HOSPITAL_DROPDOWN, PHYSICIAN_TEAM_DROPDOWN];
    case ClientType.PAYOR:
      return [PAYER_DROPDOWN, HOSPITAL_DROPDOWN, PHYSICIAN_TEAM_DROPDOWN];
    default:
      return [HOSPITAL_DROPDOWN, PHYSICIAN_GROUP_DROPDOWN, PAYER_DROPDOWN];
  }
};

export const createClientDropdown = (groupTypeId: string, label: string, filterKey: string) => ({
  ...defaultFilterDropdownConfig,
  placeholder: label,
  name: filterKey,
  getOptions: {
    hook: useInfiniteClientOptions,
    params: {
      sortBy: 'name asc',
      groupTypes: [groupTypeId],
      viewOnly: true,
    },
  },
});

const ClientDropdownLabels = {
  POST_ACUTE: 'Organization',
};

export const getClientFilter = (actingClientType: ClientType, selectedGroupTypeId: string) => {
  if ([ClientType.INPATIENT_POST_ACUTE_CARE, ClientType.OUTPATIENT_POST_ACUTE_CARE].includes(actingClientType)) {
    return [createClientDropdown(selectedGroupTypeId, ClientDropdownLabels.POST_ACUTE, 'ownerClient')];
  } else {
    return [];
  }
};

// Checkboxes
export const STATUS_OUT_OF_DATE_CHECKBOX = {
  name: 'statusOutOfDate',
  label: 'Needs Progress Update',
  filterType: FILTER_TYPES.CHECKBOX,
};

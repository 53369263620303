import {
  ADDITIONAL_FILTERS,
  COMANAGEMENT_TEAM,
  createProviderDropdown,
  EPISODE_TYPE_DROPDOWN,
  getClientFilter,
  getComanagementTeamAcuteFilters,
  LATEST_REHAB_STATE_DROPDOWN,
  PLAN_TYPE_DROPDOWN,
} from 'constants/filterConfigs';
import { ClientType } from 'models/Client';
import GroupType from 'models/GroupType';

const rehabStateDropdown = {
  ...LATEST_REHAB_STATE_DROPDOWN,
  name: 'rehabState',
};

export const renderableFilters = (selectedGroupType: GroupType, actingClientType: ClientType) => {
  return [
    {
      title: COMANAGEMENT_TEAM,
      filters: [
        ...getClientFilter(actingClientType, selectedGroupType.id),
        createProviderDropdown(selectedGroupType),
        ...getComanagementTeamAcuteFilters(actingClientType),
      ],
    },
    {
      title: ADDITIONAL_FILTERS,
      filters: [PLAN_TYPE_DROPDOWN, EPISODE_TYPE_DROPDOWN, rehabStateDropdown],
    },
  ];
};

import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import useIntersectionObserver from 'hooks/useIntersectionObserver';
import usePrevious from 'hooks/usePrevious';
import isDeepEqual from 'lib/isDeepEqual';
import { Analytics, retrieveAnalytics } from 'services/api/insights/analytics';
import { getAnalyticsFilters, useInsightsStore } from 'stores/insightsStore';

type Request<Result> = {
  params: any;
  processData: (data: Analytics) => Result;
};

type DataFetchConfig = {
  condition?: boolean;
  onIntersecting?: boolean;
};

export default function useInsightsQuery<Result = Record<string, any>>(
  request: Request<Result>,
  config: DataFetchConfig = {}
) {
  const { condition = true, onIntersecting = false } = config;

  const analyticsFilters = useInsightsStore(getAnalyticsFilters);
  const filters = useInsightsStore((state) => state.filters);
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);
  const previousFilters = usePrevious(filters);
  const previousSelectedGroupType = usePrevious(selectedGroupType);

  const [hasFetched, setHasFetched] = useState(false);

  const {
    isIntersecting: hasIntersected,
    setIsIntersecting: setHasIntersected,
    customRef,
  } = useIntersectionObserver({
    initialState: !onIntersecting,
    once: true,
    threshold: 0.2,
  });

  const query = useQuery({
    queryKey: ['analytics', request, analyticsFilters],
    queryFn: () => retrieveAnalytics(request.params, analyticsFilters),
    enabled: hasFetched,
    select: request.processData,
  });

  useEffect(() => {
    if (!isDeepEqual(previousFilters, filters) || !isDeepEqual(previousSelectedGroupType, selectedGroupType)) {
      // Reset the intersection observer when filters or selected group type change
      // so that tiles can be re-fetched once in view
      setHasFetched(false);

      if (onIntersecting) {
        setHasIntersected(false);
      }
    }
  }, [filters, onIntersecting, previousFilters, previousSelectedGroupType, selectedGroupType, setHasIntersected]);

  useEffect(() => {
    if (!hasFetched && hasIntersected && condition) {
      setHasFetched(true);
    }
  }, [condition, hasFetched, hasIntersected]);

  return { customRef, query };
}

import { useEffect } from 'react';
import { createContext, useContext } from 'react';

import InsightsPage from 'components/insights/InsightsPage';
import { useProfile } from 'context/profile';
import Profile from 'models/Profile';
import { RehabStateName } from 'models/RehabState';
import { useRehabStates } from 'services/api/rehabStates';
import { useInsightsActions, useInsightsStore } from 'stores/insightsStore';

type InsightsContextType = {
  dischargedId: string;
  profile: Profile;
};

export const InsightsContext = createContext<InsightsContextType>({
  dischargedId: '',
  profile: {} as Profile,
});

export const useInsightsContext = () => useContext(InsightsContext);

const InsightsContainer = () => {
  const { profile } = useProfile();

  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);
  const { setSelectedGroupType, resetFilters } = useInsightsActions();

  const { data: rehabStates } = useRehabStates();

  useEffect(() => {
    // Sets initial selectedGroupType to the first enabled groupType
    if (profile.enabledProviderTypes?.length || 0 > 0) {
      setSelectedGroupType(profile.enabledProviderTypes?.[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.enabledProviderTypes]);

  useEffect(() => {
    resetFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.actingClient.id]);

  if (rehabStates) {
    return (
      <InsightsContext.Provider
        value={{
          dischargedId: rehabStates?.items.find((rs) => rs.state === RehabStateName.Discharged)?.id as string,
          profile,
        }}>
        {selectedGroupType.id && <InsightsPage />}
      </InsightsContext.Provider>
    );
  } else {
    return null;
  }
};

export default InsightsContainer;

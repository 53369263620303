import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Histogram from 'components/shared/charts/Histogram';
import { Flags } from 'constants/flags';
import useIsMobile from 'hooks/useIsMobile';
import { useInsightsStore } from 'stores/insightsStore';

import { DimensionConstants, MetricConstants, RouteConstants, SortOrder, SourceConstants } from '../../../constants';
import { useInsightsContext } from '../../../InsightsContainer';
import { DataRow, parseDimensionValues, parseMetricValues } from '../../helpers/dataUtils';
import useDataFetch from '../../helpers/useDataFetch';
import ChartContainer from '../ChartContainer';
import { ChartData, generateExtendedChartConfig } from '../helpers/readmissionRateUtils';
import MetricDetailContainer from '../MetricDetailContainer';
import MetricDetailHeader from '../MetricDetailHeader';
import ViewBySelect from '../ViewBySelect';

import ReadmissionRateDataTable from './ReadmissionRateDataTable';
import ReadmittedPatientsDataTable from './ReadmittedPatientsDataTable';

const ReadmissionRateDetail = () => {
  const { profile } = useInsightsContext();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);
  const { dischargedId } = useInsightsContext();

  const [selectedDimension, setSelectedDimension] = useState<DimensionConstants>(
    profile.isAcute && profile.hasFlag(Flags.Insights)
      ? DimensionConstants.PROVIDER_CLIENT
      : DimensionConstants.GROUP_NAME
  );
  const [averageRate, setAverageRate] = useState(0);
  const [totalDischarges, setTotalDischarges] = useState(0);
  const [totalReadmissions, setTotalReadmissions] = useState(0);
  const [chartData, setChartData] = useState<ChartData>({
    categories: [],
    dischargeCounts: [],
    readmissionCounts: [],
    readmissionRates: [],
  });

  const request = useMemo(
    () => ({
      params: {
        source: SourceConstants.LOCATION_EPISODES,
        dimensions: [selectedDimension],
        metrics: [MetricConstants.ID_COUNT, MetricConstants.READMISSIONS_COUNT, MetricConstants.READMISSION_RATE],
        sortBy: `${MetricConstants.READMISSION_RATE} ${SortOrder.DESC}`,
        rollups: true,
      },
      processData: (data: DataRow[]) => {
        const parsedData = data.reduce(
          (acc, row) => {
            const dimensionValues = parseDimensionValues(row, false) as string[];
            const metricValues = parseMetricValues(row);

            const groupName = dimensionValues[0];
            const discharges = metricValues[0];
            const readmissions = metricValues[1];
            const readmissionRate = metricValues[2];

            if (!groupName && row.grouping == 1) {
              acc.averageRate = readmissionRate;
              acc.totalDischarges = discharges;
              acc.totalReadmissions = readmissions;
            } else {
              acc.categories.push(groupName || 'None');
              acc.dischargeCounts.push(discharges);
              acc.readmissionCounts.push(readmissions);
              acc.readmissionRates.push(readmissionRate);
            }

            return acc;
          },
          {
            categories: [],
            dischargeCounts: [],
            readmissionCounts: [],
            readmissionRates: [],
            averageRate: 0,
            totalDischarges: 0,
            totalReadmissions: 0,
          } as {
            categories: string[];
            dischargeCounts: number[];
            readmissionCounts: number[];
            readmissionRates: number[];
            averageRate: number;
            totalDischarges: number;
            totalReadmissions: number;
          }
        );

        const { totalDischarges, totalReadmissions, averageRate, ...chartData } = parsedData;

        setChartData(chartData);
        setAverageRate(averageRate);
        setTotalDischarges(totalDischarges);
        setTotalReadmissions(totalReadmissions);
      },
    }),
    [selectedDimension]
  );

  const { loading } = useDataFetch([request], {
    condition: dischargedId !== undefined,
  });

  const chartConfig = useMemo(() => generateExtendedChartConfig(chartData), [chartData]);

  const getAverageRateString = () => {
    if (!totalDischarges) return;

    return `${averageRate.toFixed(1)}%`;
  };

  const getAllReadmissionsString = () => {
    if (!totalDischarges) return;

    return totalReadmissions.toString();
  };

  const getTableHeader = useMemo(() => {
    switch (selectedDimension) {
      case DimensionConstants.PLAN_TYPE:
        return 'Plan Type';
      case DimensionConstants.EPISODE_TYPE:
        return 'Episode Type';
      case DimensionConstants.PROVIDER_CLIENT:
        return `${selectedGroupType?.displayName} Company`;
      default:
        return selectedGroupType?.displayName || '';
    }
  }, [selectedDimension, selectedGroupType?.displayName]);

  return (
    <>
      <MetricDetailContainer
        onBackClick={() => navigate(RouteConstants.INSIGHTS_BASE)}
        loading={loading}
        hasData={!!chartData.categories.length}
        header={
          <>
            <MetricDetailHeader label='All Cause Readmission Rate' loading={loading} value={getAverageRateString()} />
            <Separator />
            <MetricDetailHeader label='All Cause Readmissions' loading={loading} value={getAllReadmissionsString()} />
            {profile.hasFlag(Flags.Insights) && !isMobile && (
              <ViewBySelect
                selectedDimension={selectedDimension}
                groupType={selectedGroupType}
                onChange={(selectedOption) => {
                  setSelectedDimension(selectedOption.value);
                }}></ViewBySelect>
            )}
          </>
        }>
        <ChartContainer>
          <Histogram config={chartConfig} />
        </ChartContainer>
      </MetricDetailContainer>
      <ReadmissionRateDataTable
        averageRate={averageRate}
        data={chartData}
        loading={loading}
        totalDischarges={totalDischarges}
        totalReadmissions={totalReadmissions}
        firstColumnHeader={getTableHeader}
      />
      <ReadmittedPatientsDataTable />
    </>
  );
};

export default ReadmissionRateDetail;

const Separator = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.black15};
  margin: 0 24px;
`;

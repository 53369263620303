import { useMemo } from 'react';
import styled from 'styled-components';

import { DimensionConstants, MetricConstants, RouteConstants, SourceConstants } from 'components/insights/constants';
import useInsightsQuery from 'components/insights/layout/helpers/useInsightsQuery';
import MetricTile from 'components/insights/layout/tiles/MetricTile';
import MetricValue from 'components/shared/charts/MetricValue';
import { Analytics } from 'services/api/insights/analytics';
import NoteIconOutline from 'svg/NoteIconOutline';
import PatientsIcon from 'svg/PatientsIcon';

export default function ActivitiesPerPatientPerWeekTile() {
  const request = useMemo(
    () => ({
      params: {
        source: SourceConstants.LOCATION_EPISODE_DAYS,
        dimensions: [DimensionConstants.GROUP_NAME],
        metrics: [
          MetricConstants.ACTIVITIES_PER_PATIENT_PER_WEEK,
          MetricConstants.LOCATION_EPISODE_ID_COUNT,
          MetricConstants.OWNER_ACTIVITIES_SUM,
        ],
        rollups: true,
      },
      processData: (data: Analytics) => {
        let activitiesPerPatientPerWeek = 0;
        let patients = 0;
        let activities = 0;

        data.data.find((row) => {
          if (row.grouping === 1) {
            activitiesPerPatientPerWeek = Number(row.metricValues[0].value);
            patients = Number(row.metricValues[1].value);
            activities = Number(row.metricValues[2].value);
          }
        });

        return { activitiesPerPatientPerWeek, patients, activities };
      },
    }),
    []
  );

  const { customRef, query } = useInsightsQuery(request);

  return (
    <MetricTile
      ref={customRef}
      showValue={true}
      loading={query.isPending}
      data-gtm-id='activitiesPerPatientPerWeek'
      data-cy='activitiesPerPatientPerWeek'
      label='Activities Per Patient Per Week'
      value={`${query.data?.activitiesPerPatientPerWeek?.toFixed(1) || 0}`}
      hasData={(query.data?.patients || 0) > 0}
      navigateTo={RouteConstants.ACTIVITIES_PER_PATIENT_PER_WEEK}>
      <MetricWrapper>
        <MetricValue icon={NoteIconOutline} label='Activities' value={query.data?.activities || 0} />
        <MetricValue icon={PatientsIcon} label='Patients' value={query.data?.patients || 0} />
      </MetricWrapper>
    </MetricTile>
  );
}

export const MetricWrapper = styled.div`
  display: flex;
  gap: 8px;
  height: 100%;
  width: 100%;
`;
